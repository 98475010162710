import React, { useState, useEffect, useRef } from 'react'
import './style.scss'


let timer=null

const InfiniteRoll = props => {
    let [left, updateLeft]=useState(0)
    let rollWraper=useRef()

    const stopRoll=()=>{
        clearInterval(timer)
    }
    const startRoll=()=>{
        const width=rollWraper.current.offsetWidth/2
        timer=setInterval(() => {
            updateLeft(left=>{
                if(left>=width){
                    left=0
                }else{
                    left+=1
                }
                return left
            })
        }, 20);
    }
    
    useEffect(()=>{
        startRoll()
        return stopRoll
    }, [])

   

    return <div className="roll-wraper" 
        onMouseOver={stopRoll} 
        onMouseOut={startRoll}>
        <div className="roll-container" 
        ref={rollWraper} 
        style={{left:-left+'px'}}>
        {
            [props.children, props.children]
        }
        </div>
    </div>
}

export default InfiniteRoll
