import React, { useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import './style.scss'

import {images} from '../../utility/const'

const Header = (props) => {
    const lang=props.lang


    return <div className="page-head">
       <div className="head-image">
        <img className="head-image-logo" src={images.logo}></img>
        <img className="head-image-slogan" src={images.slogan}></img>
       </div>

       <div className="head-right">
        {/* <div className="head-lang" onClick={props.switchLang}>
            <span className={lang=='en'?'active':''}>EN</span> / <span className={lang=='zh'?'active':''}>中</span>
        </div> */}
        <a className="head-requestdemo button" href="https://asingularbrand.cn/app/waitlist" target="_blank">
            {intl.get("requestdemo")}
        </a>    
       </div>
    </div>
}

export default Header
