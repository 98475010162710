import React, { useState, useEffect } from 'react'
import intl, { init } from 'react-intl-universal'
import './style.scss'

import Header from '../../components/Header'
import InfiniteRoll from '../../components/InfiniteRoll'
import HomeMainInfo from '../../components/HomeMainInfo'
import {images} from '../../utility/const'

const Home=(props)=>{
    useEffect(()=>{
        window.fd('form', {
            "formId": '5f7c9280bf43d870bebfb181',
            "containerEl": '#fd-form-5f7c9280bf43d870bebfb181'
        });
    },[])


    // 中英文相关
    const [done, updateDone]=useState(false)
    const [lang]=useState(localStorage.getItem('lang')||'en')
    const locales={
        zh:require('../../assets/languages/zh.json'),
        en:require('../../assets/languages/en.json')
    }
    const loadLang=()=>{
        console.log(lang, 'lang')
        intl.init({
            currentLocale:lang,
            locales
        })
        .then(res=>{
            updateDone(true)
        })
    }
    useEffect(()=>{
        loadLang()
    },[])
    const switchLang=()=>{
        let currentLang='en'
        switch (lang) {
            case 'zh':
                currentLang='en'
                break;
            case 'en':
                currentLang='zh'
                break;
            default:
                break;
        }
        localStorage.setItem('lang',currentLang)
        window.location.reload()
    }
   
    

   
    return done&&<div className="home-wraper min-width">
        <Header lang={lang} switchLang={switchLang}></Header>

        <HomeMainInfo></HomeMainInfo>

        <div className="home-powerbrandimage">
            <div className="home-powerbrandimage-left">
                <div className="home-powerbrandimage-left-wraper">
                    <img src={images.powerBrandImage_left}></img>
                </div>
            </div>
            <div className="home-powerbrandimage-center">
                <img src={images.powerBrandImage_center}></img>
            </div>
            <div className="home-powerbrandimage-right">
                <div className="home-powerbrandimage-right-container">
                    <div className="title">
                        {intl.get("powerbrand_image_title")}
                    </div>
                    <div className="home-powerbrandimage-desc">
                        <p>* {intl.get("powerbrand_image_text1")}</p>
                        <p>* {intl.get("powerbrand_image_text2")}</p>
                        <p>* {intl.get("powerbrand_image_text3")}</p>
                        <p>* {intl.get("powerbrand_image_text4")}</p>
                    </div>
                    <a className="tryit-btn button" href="https://asingularbrand.cn/app/login" target="_blank">
                        {intl.get("login")}
                    </a> 
                </div>
            </div>
        </div>

        <div className="home-features">
            <div className="title">Redefine Fashion Landscape</div>
            <div className="home-features-list">
                <div className="features-item">
                    <div className="item-icon">
                        <img src={images.icon_feature1}></img>
                    </div>
                    <div className="item-name">1st B2B2C Platform</div>
                    <div className="item-desc">One-stop Management</div>
                </div>
                <div className="features-item">
                    <div className="item-icon">
                        <img src={images.icon_feature2}></img>
                    </div>
                    <div className="item-name">Cross-Border</div>
                    <div className="item-desc">Bi-lingual Global-CN</div>
                </div>
                <div className="features-item">
                    <div className="item-icon">
                        <img src={images.icon_feature3}></img>
                    </div>
                    <div className="item-name">Consumer-Centric</div>
                    <div className="item-desc">D2C-Direct to Consumer</div>
                </div>
            </div>
            <div className="home-features-list">
                <div className="features-item">
                    <div className="item-icon">
                        <img src={images.icon_feature4}></img>
                    </div>
                    <div className="item-name">Integration</div>
                    <div className="item-desc">O2O Marcomm + Sales Directory</div>
                </div>
                <div className="features-item">
                    <div className="item-icon">
                        <img src={images.icon_feature5}></img>
                    </div>
                    <div className="item-name">Optimization</div>
                    <div className="item-desc">20% Cost for 80% Work</div>
                </div>
                <div className="features-item">
                    <div className="item-icon">
                        <img src={images.icon_feature6}></img>
                    </div>
                    <div className="item-name">Agile System</div>
                    <div className="item-desc">Easy for Extension</div>
                </div>
            </div>
        </div>

        <div className="home-brandslide">
            <div className="title">Brands with Us</div>
            <InfiniteRoll>
                <div className="slides-wraper">
                    {
                        images.slides.map((slide, index)=>(
                            <div className="slide" 
                            key={index} 
                            style={{backgroundImage:`url(${slide})`}}>
                            </div>
                        ))
                    }
                </div>
            </InfiniteRoll>
        </div>

        <div className="home-entryinfo">
            <div className="home-entryinfo-row1">
                <div className="home-entryinfo-left">
                    <div className="entryinfo-name" style={{textAlign:"right"}}>For More Viewing</div>
                    <div className="entryinfo-desc" style={{textAlign:"right"}}>Scan QR Code</div>
                    <div className="entryinfo-arrow">
                        <img src={images.arrow_black}></img>
                    </div>
                </div>
                <div className="home-entryinfo-right">
                    <div className="entryinfo-qrcode">
                        <img src={images.miniprogram_qrcode}></img>
                    </div>
                </div>
            </div>
            <div className="home-entryinfo-row2">
                <div className="home-entryinfo-left">
                    <a  href="https://asingularbrand.cn/app/waitlist" target="_blank" className="button">Request Demo</a>
                </div>
                <div className="home-entryinfo-right">
                    <div className="entryinfo-name">For Brands/Media</div>
                    <div className="entryinfo-desc">Join The Waitlist</div>
                    <div className="entryinfo-arrow">
                        <img src={images.arrow_black}></img>
                    </div>
                </div>
            </div>
        </div>

        <div className="home-grayinfo">
            <div className="home-grayinfo-title">Media/Consumers Direct Acceleration</div>
            <div className="home-grayinfo-desc">
                The New Way to Know Your Media/Customers & INTERACT With Them
            </div>
        </div>

        <div className="home-module">
            <div className="home-module-icon">
                <img src={images.logo}></img>
            </div>
            <div className="home-module-items">
                <div className="item">
                    <div className="item-name">Platform</div>
                    <div className="item-desc">Connection</div>
                </div>
                <div className="item">
                    <div className="item-name">Database</div>
                    <div className="item-desc">Analysis</div>
                </div>
                <div className="item">
                    <div className="item-name">Content/Events</div>
                    <div className="item-desc">Distribution</div>
                </div>
                <div className="item">
                    <div className="item-name">Sales Directory</div>
                    <div className="item-desc">Analysis</div>
                </div>
            </div>
        </div>

        <div className="home-partners">
            <div className="title">BRANDS</div>
            <div className="partners">
                {
                    images.brands.map((img,index)=>(
                        <div className="partner" key={index}>
                            <img src={img}></img>
                        </div>
                    ))
                }
            </div>
            <div className="title" style={{marginTop:"0.5rem"}}>PARTNERS</div>
            <div className="partners center">
                {
                    images.partners.map((img,index)=>(
                        <div className="partner" key={index}>
                            <img src={img}></img>
                        </div>
                    ))
                }
            </div>
        </div>

        <div id="fd-form-5f7c9280bf43d870bebfb181" className="ff-waitlist"></div>
        
        {/* <div className="home-waitlist">
            <div className="title">Enter Waitlist</div>
            
            <div className="waitlist-form">
                <div className="form-input">
                    <input type="text" placeholder="Please type your name"></input>
                </div>
                <div className="form-input">
                    <input type="text" placeholder="Please type your email"></input>
                </div>
                <div className="form-submit button">Submit</div>
            </div>
        </div> */}
        
        <div className="home-contact">
            <div className="title">Contact Us</div>
            <div className="contact-qrcode">
                <div className="qrcode-text">Scan To Talk To Our WeChat Assistant</div>
                <div className="qrcode-image">
                    <img src={images.qrcode}></img>
                </div>
            </div>
            <div className="contact-media">
                <a href="https://www.facebook.com/asingularbrand" target="_blank" className="media-link">
                    <img src={images.media_facebook}></img>
                </a>
                <a href="https://www.instagram.com/asingularbrand/" target="_blank" className="media-link">
                    <img src={images.media_instagram}></img>
                </a>
                <a href="https://mp.weixin.qq.com/s/e29s0S-XjxfJo6JKrMnd1A" target="_blank" className="media-link">
                    <img src={images.media_weixin}></img>
                </a>
                <a href="https://weibo.com/asingularbrand" target="_blank" className="media-link">
                    <img src={images.media_weibo}></img>
                </a>
            </div>
        </div>


        <div className="page-footer">
            <div className="footer-copyright">
                ASINGULARBRAND © 2020 | 希溥（深圳）网络技术有限公司
            </div>
            <div className="footer-beian">
                <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/recordQuery">
                    <img src={images.icon_beian} />
                    <p>粤ICP备20027545号</p >
                </a >
            </div>
        </div>
    </div>
}
  

export default Home
