import React, { useState, useEffect } from 'react'
import './style.scss'

const NotFound = props => {
    return <div className="not-found">
    404 not found
    </div>
}

export default NotFound
