import React from "react"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import routes from './routes'

// load default style
import './assets/styles/normalize.css'
// load common style
import './assets/styles/common.scss'

// component NotFount
import NotFound from './components/NotFound'


const App = (props) => {
  console.log('--------------- app render ----------------',props)

  return (
    <BrowserRouter>
      <Switch>
        {
          routes.map((router, index) => (
            <Route
              key={index}
              exact={true}
              path={router.path}
              render={props => {
                return <router.component {...props} />
              }}
            />
          ))
        }
        <Route path="*">
          <NotFound></NotFound>
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default App