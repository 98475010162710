import React, { useState, useEffect } from 'react'
import intl from 'react-intl-universal'
import './style.scss'

const HomeMainInfo = props => {
    const [activeIndex, updateActiveIndex]=useState(0)
    const texts=[
        intl.get('maininfo_dynamictext1'),
        intl.get('maininfo_dynamictext2'),
        intl.get('maininfo_dynamictext3'),
    ]
    const getText=()=>{
        return texts.map((text,index)=>(
            <div key={index} className={`dynamic-text ${activeIndex==index?"active":""}`}>
                {text}
            </div>
        ))
    }

    useEffect(()=>{
        const max=texts.length
        const timer=setInterval(() => {
            updateActiveIndex(index=>{
                index=index+1
                if(index>=max)index=0
                return index
            })
        }, 4200);
    },[])

    return <div className="home-maininfo">
        <div className="title">{intl.get('maininfo_title1')}</div>
        <div className="dynamic-wraper">
            { getText() }
        </div>
        <div className="maininfo-desc">
            {intl.getHTML("maininfo_desc")}
        </div>
        <div className="maininfo-requestdemo">
            <a className="requestdemo-btn button" href="https://asingularbrand.cn/app/waitlist" target="_blank">
                {intl.get("requestdemo")}
            </a>
            <div className="requestdemo-text">
                {intl.get("maininfo_requestdemo_text")} 
                <a href="https://asingularbrand.cn/app/login" target="_blank"> {intl.get('login')}</a> 
            </div>
        </div>
    </div>
}

export default HomeMainInfo
