import Home from '../pages/home/index'


const routes = [
    {
        path: "/home",
        component: Home
    },
    {
        path: "/",
        component: Home
    }
]


export default routes