/**
 * 静态变量存储
 *
 *
*/


//页面所需静态图片地址
export const images={
    logo:require('../assets/images/logo.png'),
    slogan:require('../assets/images/slogan.png'),
    powerBrandImage_left:require('../assets/images/powerBrandImage_left.png'),
    powerBrandImage_center:require('../assets/images/powerBrandImage_center.png'),
    icon_feature1:require('../assets/images/icon_feature1.png'),
    icon_feature2:require('../assets/images/icon_feature2.png'),
    icon_feature3:require('../assets/images/icon_feature3.png'),
    icon_feature4:require('../assets/images/icon_feature4.png'),
    icon_feature5:require('../assets/images/icon_feature5.png'),
    icon_feature6:require('../assets/images/icon_feature6.png'),
    slides:[
        require('../assets/images/brandslide/1.png'),
        require('../assets/images/brandslide/2.jpeg'),
        require('../assets/images/brandslide/3.jpg'),
        require('../assets/images/brandslide/4.jpeg'),
        require('../assets/images/brandslide/5.jpg'),
        require('../assets/images/brandslide/6.jpg'),
        require('../assets/images/brandslide/13.png'),
        require('../assets/images/brandslide/7.jpg'),
        require('../assets/images/brandslide/8.jpg'),
        require('../assets/images/brandslide/9.png'),
        require('../assets/images/brandslide/10.jpg'),
        require('../assets/images/brandslide/11.png'),
        require('../assets/images/brandslide/12.jpg'),
    ],
    arrow_black:require('../assets/images/arrow_black.png'),
    miniprogram_qrcode:require('../assets/images/miniprogram_qrcode.png'),
    qrcode:require('../assets/images/qrcode.png'),
    brands:[
        require('../assets/images/partners/1.png'),
        require('../assets/images/partners/2.png'),
        require('../assets/images/partners/3.png'),
        require('../assets/images/partners/4.png'),
        require('../assets/images/partners/13.png'),
        require('../assets/images/partners/12.png'),
        require('../assets/images/partners/17.png'),

        require('../assets/images/partners/8.png'),
        require('../assets/images/partners/9.png'),
        require('../assets/images/partners/10.png'),
        require('../assets/images/partners/11.png'),
        require('../assets/images/partners/6.png'),
        require('../assets/images/partners/5.png'),
        require('../assets/images/partners/14.png'),

        require('../assets/images/partners/15.png'),
        require('../assets/images/partners/16.png'),
        require('../assets/images/partners/7.png'),
        require('../assets/images/partners/18.png'),
        require('../assets/images/partners/19.png'),
        require('../assets/images/partners/20.png'),
        require('../assets/images/partners/21.png'),

        require('../assets/images/partners/22.png'),
        require('../assets/images/partners/23.png'),
        require('../assets/images/partners/24.png'),
        require('../assets/images/partners/25.png'),
        require('../assets/images/partners/26.png'),
        require('../assets/images/partners/27.png'),
        require('../assets/images/partners/28.png'),

        require('../assets/images/partners/29.png'),
        require('../assets/images/partners/30.png'),
        require('../assets/images/partners/31.png'),
        require('../assets/images/partners/32.png'),
        require('../assets/images/partners/33.png')
    ],
    partners:[
        require('../assets/images/partners/partner1.png'),
        require('../assets/images/partners/partner2.png'),
        // require('../assets/images/partners/partner3.png'),
        // require('../assets/images/partners/partner4.png')
    ],
    media_facebook:require('../assets/images/icon_media_facebook.png'),
    media_instagram:require('../assets/images/icon_media_instagram.png'),
    media_weibo:require('../assets/images/icon_media_weibo.png'),
    media_weixin:require('../assets/images/icon_media_weixin.png'),
    icon_beian:require('../assets/images/icon_beian.png'),
}



// 环境配置: local, only one env
let api_prefix=''               

switch(process.env.REACT_APP_ENV){
    case 'local':
        api_prefix='/api'
    break;
}

export  {
    api_prefix
}
